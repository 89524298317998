import React from "react";

const FeaturedHeader = () => {

    return (
        <>
            <div className="hero d-flex flex-fill flex-column align-items-center" >
                <div className="hero-wrap d-flex flex-fill align-items-center">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-9 mx-auto">
                                <h2>Your Company's greatest Ally!</h2>
                            </div>
                            <div className="col-md-6 mx-auto">
                                <p className="mb-7">Code Advanced LTD (PTY) excels in building custom software products, software consultancy and management with goals to expand, train and grow young talent. <br />
                                    <br />
                  We are a values-driven organisation. Our values and behaviours guide the way we work with our clients, business partners and each other to help your company reach it's full potential.
              </p>
                                {/* <div className="row row-x-10 justify-content-center">
                <div className="col-auto theme-green mb-3">
                  <a href="#" className="btn btn-lg btn-primary">Get Started Now</a>
                </div>
                <div className="col-auto theme-blue mb-3">
                  <a href="#" className="btn btn-lg btn-white btn-outline">Learn More</a>
                </div>
              </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeaturedHeader;