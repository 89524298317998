import React from "react";
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
    const data = useStaticQuery(
        graphql`
            {
              logoImage: file(relativePath: {eq: "logo-dark.png"}) {
                childImageSharp {
                  fluid(maxWidth: 236) {
                    src
                  }
                }
              }
            }
        `
    );

    return (
        <>
            <footer className="footer style-one theme-blue">
                <div style={{ display: "none" }} className="footer-wrapper pt-100px pb-60px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 mb-5 align-self-center">
                                <div className="footer-logo mb-6">
                                    <img src={`${data.logoImage.childImageSharp.fluid.src}`} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="row">
                                    <div className="col-6">
                                        {/* <h4 className="mb-4">About Us</h4>
                                        <ul className="list-links">
                                            <li>
                                                <a href="#">Return Policy</a>
                                            </li>
                                            <li>
                                                <a href="#">Privacy Policy</a>
                                            </li>
                                            <li>
                                                <a href="#">Terms &amp; Conditions</a>
                                            </li>
                                            <li>
                                                <a href="#">Site Map</a>
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div className="col-6">
                                        {/* <h4 className="mb-4">Company</h4>
                                        <ul className="list-links">
                                            <li>
                                                <a href="#">Business</a>
                                            </li>
                                            <li>
                                                <a href="#">Stories</a>
                                            </li>
                                            <li>
                                                <a href="#">Customers</a>
                                            </li>
                                            <li>
                                                <a href="#">Our Pricing</a>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="row">
                                    <div className="col-6">
                                        {/* <h4 className="mb-4">Resources</h4>
                                        <ul className="list-links">
                                            <li>
                                                <a href="#">Resource</a>
                                            </li>
                                            <li>
                                                <a href="#">Resource Name</a>
                                            </li>
                                            <li>
                                                <a href="#">Another Resource</a>
                                            </li>
                                            <li>
                                                <a href="#">Final Resource</a>
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div className="col-6">
                                        {/* <h4 className="mb-4">Support</h4>
                                        <ul className="list-links">
                                            <li>
                                                <a href="#">Help Desk</a>
                                            </li>
                                            <li>
                                                <a href="#">Documentation</a>
                                            </li>
                                            <li>
                                                <a href="#contactus">Contact Us</a>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom bg-primary">
                    <div className="container">
                        <div className="row py-5">
                            <div class="col-md-4 col-lg-4 mb-5 mb-lg-0">
                                <h5 class="text-5 text-transform-none font-weight-semibold text-color-light mb-4">Contact Details</h5>
                                <p class="text-4 mb-0">Workshop 17</p>
                                <p class="text-4 mb-0">138 West Street</p>
                                <p class="text-4 mb-0">Sandown, Sandton 2031</p>
                            </div>
                            <div class="col-md-4 col-lg-4 mb-5 mb-lg-0">
                                <h5 class="text-5 text-transform-none font-weight-semibold text-color-light mb-4">Opening Hours</h5>
                                <p class="text-4 mb-0">Mon-Fri: <span class="text-color-light">8:30 am to 5:00 pm</span></p>
                                <p class="text-4 mb-0">Saturday: <span class="text-color-light">Closed</span></p>
                                <p class="text-4 mb-0">Sunday: <span class="text-color-light">Closed</span></p>
                            </div>
                            <div class="col-md-4 col-lg-4 mb-5 mb-lg-0">
                                <h5 class="text-5 text-transform-none font-weight-semibold text-color-light mb-4">Call Us Now</h5>
                                <p class="text-7 text-color-light font-weight-bold mb-2">
                                    <a href="tel:012345679" class="text-decoration-none text-color-light">(010) 020 6354</a>
                                </p>
                                <p class="text-4 mb-0">
                                    Sales: 
                                    <span class="text-color-light">
                                        <a href="tel:012345679" class="text-decoration-none text-color-light">(010) 020 6354</a>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="row py-4" style={{ 'border-top' : "1px solid rgba(255, 255, 255, 0.05)" }}>
                            <div className="col">
                                <ul className="list-inline m-0">
                                    <li className="list-inline-item">
                                        <span>Follow Us: </span>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://web.facebook.com/codeadvanced" target="_blank" rel="noopener noreferrer">
                                            <i className="icon fab fa-facebook-square"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://twitter.com/CodeAdvanced1" target="_blank" rel="noopener noreferrer">
                                            <i className="icon fab fa-twitter-square"></i>
                                        </a>
                                    </li>
                                    {/* <li className="list-inline-item">
                                        <a href="#">
                                            <i className="icon fab fa-instagram"></i>
                                        </a>
                                    </li> */}
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/company/codeadvanced" target="_blank" rel="noopener noreferrer">
                                            <i className="icon fab fa-linkedin"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-auto">&copy; 2022 <a href="#home">CODE ADVANCED</a>, All Right Reserved.</div>
                        </div>
                    </div>
                </div>
                <script/>
            </footer>
        </>
    )
}

export default Footer;
