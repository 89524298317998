import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"


class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = { showNavBar: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu(e) {
    this.setState({ showNavBar: !this.state.showNavBar });
  }

  render() {
    const { children } = this.props;

    return <StaticQuery query={graphql`
    {
      logoImage: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          fluid(maxWidth: 300) {
            src
          }
        }
      }
      curvedBg: file(relativePath: {eq: "hero-shape-1.png"}) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            src
          }
        }
      }
      headerBg: file(relativePath: {eq: "hero-bg.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            src
          }
        }
      }
    }
  `} render={data => (
        <header id="home" className="header d-flex flex-column" data-bg-img={`${data.curvedBg.childImageSharp.fluid.src}`}
          style={{
            backgroundImage: `url(${data.headerBg.childImageSharp.fluid.src})`
          }}
        >
          <div className="overlay theme-blue bg-primary opacity-90"></div>
          {/* <div className="curved-bg overlay bg-contain bg-cb" data-bg-img={`${data.curvedBg.childImageSharp.fluid.src}`}
      style={{
        backgroundImage: `url(${data.curvedBg.childImageSharp.fluid.src})`
      }}></div> */}
          <nav className="header-navbar navbar navbar-expand-lg">
            <div className="container">
              <a href="/#home" className="navbar-brand" data-bg-img={data.logoImage.childImageSharp.fluid.src}
                style={{ backgroundImage: `url("${data.logoImage.childImageSharp.fluid.src}")` }}>Code Advanced</a>
              <button className="navbar-toggler" onClick={this.toggleMenu} type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className={`collapse navbar-collapse ${this.state.showNavBar ? 'show' : ''}`} id="navbar">
                <ul className="navbar-nav ml-auto">
                  <li><a className="nav-link" href="/#home">Home</a></li>
                  <li><a className="nav-link" href="/#aboutus">About Us</a></li>
                  <li><a className="nav-link" href="/#our-values">Our Values</a></li>
                  <li><a className="nav-link" href="/#services">Services</a></li>
                  {/* <li><a className="nav-link" href="/blog">Blog</a></li> */}
                  {/* <li><a className="nav-link" href="/#contactus">Contact</a></li> */}
                </ul>
                {/* <form className="form-inline theme-green">
            <button className="btn btn-sm btn-primary" type="button">Sign In</button>
          </form> */}
              </div>
            </div>
          </nav>
          {children}
        </header>
      )
      } />
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
